import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import hero_img3 from '../../Assets/images/hero-3.jpeg'
import stats from '../../Assets/images/stats.png'
import twienty24 from '../../Assets/images/24-7.png'
import success from '../../Assets/images/success.png'
import GoldBtn from '../GoldBtn/GoldBtn'


import AOS from 'aos';
import 'aos/dist/aos.css';
const Hero = () => {


    useEffect(() => {
        AOS.init(
            {
                delay: 100,
                duration: 2000,
                once: true,
            }
        );
    })
  return (
    <div className='py-5 mt-7 container' data-aos="fade-right">


            <div className="row d-flex justify-content-end align-items-center">
                <div className="col-lg-6 col-md-12 info-right no-padding">
                    <h1 className='text-uppercase'>Discover the<br />
                    <span className='text-main'>Elite</span> world</h1>
                    <p className='p-empowering'>
                        DISCOVER THE ELITE WORLD Partnering with Elite Callers gives you access to a wealth of sales knowledge. 
                        In addition to being knowledgeable experts, our virtual assistants are committed, 
                        enthusiastic, and interested in your business's success. You can boost productivity, 
                        cut down on time, and see a noticeable improvement in your sales success with our help.
                         Start your journey right away to take advantage of this fantastic opportunity to boost 
                         your sales efforts!
                    </p>
                    <div className='mt-50'>
                    <GoldBtn mylink={'/plans'} />
                    </div>  					
                </div>	
                <div className="col-lg-6 col-md-12 info-left no-padding ">
                    <img className="img-fluid rounded-4" src={hero_img3} alt />
                </div>
            </div>


            <div className="mt-50">
                <div className="row">
                    <div className="single-feature col-lg-4 p-4">
                        <img className="img-fluid" src={stats} width={60} alt="stats"/>
                        <h4>Generating leads virtually</h4>
                        <p className='p-empowering'>With our virtual assistants hard work and commitment, we are able to guarantee and provide a continuous pipeline generating leads to your business, tailored to your needs.</p>
                    </div>
                    <div className="single-feature col-lg-4 p-4">
                        <img className="img-fluid" src={twienty24} width={60} alt="bussiness" />
                        <h4>24/7 customer service</h4>
                        <p className='p-empowering'>Customer support all around the clock, making sure there is no issue left unsolved . We take care of our clients and their needs. We pride ourselves in being the fastest to solve any issues.</p>
                    </div>
                    <div className="single-feature col-lg-4 p-4">
                        <img className="img-fluid" src={success} width={60} alt="bussiness" />
                        <h4>Handling All Issues</h4>
                        <p className='p-empowering'>At Elite Callers, we tackle any issue with ease. Count on us for prompt and effective solutions, allowing you to focus on your business with confidence.</p>
                    </div>												
                </div>
            </div>


    </div>
  )
}

export default Hero
