import React from 'react'
import logo from '../../Assets/images/logo_1.png'
import { Link, useNavigate } from 'react-router-dom'
import GoldBtn from '../GoldBtn/GoldBtn.jsx'

// import GoldBtn from '../GoldBtn/GoldBtn'

function Navbar() {
    let navigate = useNavigate()

    function changeBg(e) {
        let y = e.target
        console.log(y);
        y.classList.add('nnnnn')
        if(y.classList.contains('mm-active') == false){
            y.classList.remove('nnnnn')
            console.log(y);
        }
    }
    return (


        // <div id="logo">
        //     <a href="#"><img src={logo} width={190} alt="elite-caller-logo"/></a>
        // </div>

            <nav className="navbar navbar-expand-lg navbar-light fixed-top navbar-bg">
            <div className="container">
                <Link to={'/home'} className="navbar-brand">
                    <img src={logo} width={190} alt="elite-caller-logo" />
                </Link>
                <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapsibleNavId" aria-controls="collapsibleNavId" aria-expanded="false"
                    aria-label="Toggle navigation">
                    {/* <span className="navbar-toggler-icon"></span> */}
                    <i class="fa-solid fa-bars fs-1 text-white"></i>
                </button>
                <div className="collapse navbar-collapse align-items-center" id="collapsibleNavId">


                    <ul className="navbar-nav ms-auto mb-2 mt-lg-0">
                        <ul className="text-white d-flex list-unstyled mt-3">
                            <li className='mx-3 font-sm text-white'>
                                <Link to={'/home'} onClick={changeBg}>HOME</Link>
                            </li>
                            <li className='mx-3 font-sm text-white'>
                                <Link to={'/plans'} onClick={changeBg}>PLANS</Link>
                            </li>
                            <li className='mx-3 font-sm text-white'>
                                <Link to={'/contact'} onClick={changeBg}>CONTACT</Link>
                            </li>
                            <li>
                            <div className='ms-l'>
                                {/* <Link to={'/plan'} className='fw-bold font-sm get-startedBtn rounded-5'>Get Started</Link> */}
                                <GoldBtn mylink={'/plans'} />
                                
                            </div>
                            </li>
                        </ul>
                    </ul>




                </div>
            </div>
        </nav>

    )
}

export default Navbar