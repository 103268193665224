import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

function Footer() {

    let navigate = useNavigate()

    return (
<footer className="footer-area section-gap text-white">
  <div className="container">
    <div className="row">
      <div className="col-lg-4  col-md-6 col-sm-6">
        <div className="single-footer-widget">
          <h4 className="text-white">About Us</h4>
          <p>
          We are Elite Callers, your premier cold calling experts. With a focus on personalized outreach and exceptional results, our dedicated team is committed to driving success for your business. Let us maximize your opportunities and elevate your growth.
            </p>
        </div>
      </div>
      <div className="col-lg-4  col-md-6 col-sm-6">
        <div className="single-footer-widget">
          <h4 className="text-white">Contact Us</h4>
          <p>
          Feel free to reach out to us at Elite Callers for all your cold calling needs. Whether you have questions, inquiries, or are ready to start a project, our team is here to assist you. Contact us today via phone or email to begin the conversation and explore how we can help drive success for your business.
            </p>
          <p className="number">

          <span className='text-white fs-5'>Email:</span> <a className='fw-light number text-decoration-underline fs-6' href="mailto:info@elitecallers.com">info@elitecallers.com</a> <br />
          
          </p>
        </div>
      </div>						
      <div className="col-lg-4  col-md-6 col-sm-6">
        <div className="single-footer-widget">
          <div className='mt-50'>
            <Link to={'/plans'} className='text-black fw-bold font-sm get-startedBtn rounded-4'>Get Started</Link>
          </div>       
        </div>
      </div>						
    </div>
    <div className="footer-bottom d-flex justify-content-between align-items-center flex-wrap">
      <p className="footer-text text-white m-0">Copyright © All rights reserved 2024 </p>
    </div>
  </div>
</footer>

    )
}

export default Footer