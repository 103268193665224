
import Empowering from '../Empowering/Empowering'
import Hero from '../Hero/Hero'
import Proudly from '../Proudly/Proudly'
import { Helmet } from 'react-helmet'
import EliteAdvantage from '../eliteAdvantage/EliteAdvantage'
import Pricing from '../Pricing/Pricing'
import Insights from '../Insights/Insights'
import HomeForm from '../HomeForm/HomeForm'
import { useEffect, useState } from 'react'
import Loading from '../Loading/Loading'





function Home() {

    
    const [loading, setLoading] = useState(true)
  

    useEffect(() => {
      setTimeout(() => setLoading(false), 900)
    }, [])

    return (
        <>
            <Helmet>
                <title>Home | ELITE CALLERS</title>
            </Helmet>
            {loading === false ? (
                <>
                    <Hero />
                    <Empowering />
                    <Proudly />
                    <EliteAdvantage/>
                    <Pricing/>
                    <Insights/>
                    <HomeForm />
                </>
            ) : (
            <Loading />
            )}
            
        </>
    )
}

export default Home