import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Insights = () => {
    useEffect(() => {
        AOS.init(
            {
                delay: 100,
                duration: 2000,
                once: true
            }
        );
    })
  return (
    <div className='py-5'>

        <div className="container py-5">
            <div className="title" data-aos="zoom-out-up" data-aos-delay="100">
                <h1 className='text-uppercase text-start mb-2'><span className='title-insights'>Key</span> insights</h1>
            </div>
            <div className="row gap-5 p-4">

                <div className="col single-fact glassy" data-aos="zoom-out-up" data-aos-delay="250">
                    <h2 className="counter w-40">300+</h2>
                    <p>Projects Completed</p>
                </div>

                <div className="col single-fact glassy" data-aos="zoom-out-up" data-aos-delay="400">
                    <h2 className="counter">Over 150</h2>
                    <p>Really Happy Clients</p>
                </div>

                <div className="col single-fact glassy" data-aos="zoom-out-up" data-aos-delay="550">
                    <h2 className="counter">Just under 200</h2>
                    <p>Total Tasks Completed</p>
                </div>

                <div className="col single-fact glassy" data-aos="zoom-out-up" data-aos-delay="700">
                    <h2 className="counter">20+ Clients</h2>
                    <p>Cups of Coffee Taken</p>
                </div>	

            </div>
        </div>


    </div>
  )
}

export default Insights
