import React from 'react'
import { Link } from 'react-router-dom'

export default function GoldBtn(props) {
  return (<>
    <Link to={props.mylink} target={props.targety?"_blank":""} className={props.styling!=null?props.styling+" fw-medium font-sm get-startedBtn rounded-5":"fw-medium font-sm get-startedBtn rounded-5"}>
      {/* Get Started */}
      {props.text!=null?props.text:"Get Started"}
    </Link>
  </>
  )
}


// className='fw-bold font-sm get-startedBtn rounded-5'