import React, { useEffect, useState } from 'react'

import Empowering from '../Empowering/Empowering'
import Hero from '../Hero/Hero'
import Proudly from '../Proudly/Proudly'
import { Helmet } from 'react-helmet'
import EliteAdvantage from '../eliteAdvantage/EliteAdvantage'
import Pricing from '../Pricing/Pricing'
import Insights from '../Insights/Insights'
import HomeForm from '../HomeForm/HomeForm'
import PlansPricing from '../PlansPricing/PlansPricing'
import Loading from '../Loading/Loading'

const Plans = () => {


  const [loading, setLoading] = useState(true)
  

  useEffect(() => {
    setTimeout(() => setLoading(false), 800)
  }, [])


  return (
    <>
        <Helmet>
            <title>Plans | ELITE CALLERS</title>
        </Helmet>
        
        {loading === false ? (
            <>
              <div className='mt-100'>
                  <PlansPricing/>
              </div>
            </>
        ) : (
        <Loading />
        )}
    </>
  )
}

export default Plans
