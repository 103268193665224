import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import img1 from '../../Assets/images/info-img.jpg'
import stats from '../../Assets/images/hourglass.png'
import twienty24 from '../../Assets/images/wallet.png'
import success from '../../Assets/images/results.png'

import AOS from 'aos';
import 'aos/dist/aos.css';
const EliteAdvantage = () => {
    useEffect(() => {
        AOS.init(
            {
                delay: 100,
                duration: 2000,
                once: true
            }
        );
    })
  return (
    <div className="py-5 mt-5 container">
        <div className="row d-flex justify-content-between align-items-center">
            <div className="col-lg-6 col-md-12 info-left no-padding" data-aos="fade-right">
                <img className="img-fluid" src={img1} alt />
            </div>
            <div className="col-lg-5 col-md-12 info-right no-padding" data-aos="fade-left">
                <h1>ELITE CALLERS <br />
                ADVANTAGE</h1>
                <p className='p-empowering'>
                At Elite Callers we shine in providing top-notch virtual assistants who are experienced in making outbound calls, vetting prospects from your data lists, and delivering qualified leads right to your desired destination. Our team of skilled professionals are dedicated to boosting your sales process, setting appointments with potential clients, and helping you close deals.
                </p>
                <div className="">
                <div className="row flex-column g-5 mb-5">
                    
                    <div className="d-flex gap-3 align-items-center">
                        <img className="img-fluid glassy-icon" src={stats} alt="stats"/>
                        <h6>24/7 customer service</h6>
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                        <img className="img-fluid glassy-icon" src={twienty24} alt="bussiness" />
                        <h6>Generating leads virtually</h6>
                    </div>

                    <div className="d-flex gap-3 align-items-center">
                        <img className="img-fluid glassy-icon" src={success} alt="bussiness" />
                        <h6>Handling All Issues</h6>
                    </div>												
                    </div>
                </div>
                <Link to={'/plans'} className='text-black fw-bold font-sm get-startedBtn rounded-4'>Get Started</Link>   
            </div>	

        </div>
    </div>
  )
}

export default EliteAdvantage
