import React, { useEffect } from 'react'
import emailjs from '@emailjs/browser';
import GoldBtn from '../GoldBtn/GoldBtn';
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function ContactForm() {


    useEffect(() => {
        AOS.init(
            {
                delay: 100,
                duration: 2000,
            }
        );
    })

    function sendEmail(e){
        e.preventDefault();

        emailjs.sendForm('service_95rzcf7', 'template_exjd5vn', e.target, 'c4mn73Ety8A39zkIG')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error);
        });
        e.target.reset();
    }

  return (
    <>

        <form className="form-area pb-60 mt-20 position-relative" id="myForm" onSubmit={sendEmail} data-aos="zoom-out">
            
            <div className="">

                <div className="form-group d-flex justify-content-center align-items-center flex-column w-50 m-auto">
                    <input name="from_name" placeholder="Enter your name" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your name'" className="common-input mb-20 form-control" required type="text" />
                    <input name="from-email" placeholder="Enter email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter email address'" className="common-input mb-20 form-control" required type="email" />
                    <input name="from-phone" placeholder="Enter your phone" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your phone'" className="common-input mb-20 form-control" required type="phone" />
                    <button className="send-message-btn fw-medium">Submit<span className="lnr lnr-arrow-right" /></button>
                </div>

            </div>

        </form>   
    </>
  )
}
