import React, { useEffect } from 'react'
import emailjs from '@emailjs/browser';
import GoldBtn from '../GoldBtn/GoldBtn';
import AOS from 'aos';
import 'aos/dist/aos.css';

const HomeForm = () => {

    useEffect(() => {
        AOS.init(
            {
                delay: 100,
                duration: 2000,
                once: true
            }
        );
    })

    function sendEmail(e){
        e.preventDefault();

        emailjs.sendForm('service_kj866ki', 'template_0vzqdoh', e.target, 'SqpNndfZ3LMVBrgq2')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error);
        });
        e.target.reset();
    }


  return (
        <div className="bg-elite p-5">


            <div className="container">
									
                <div className="row justify-content-between">

                    <div className="fst-child col-md-6"  data-aos="zoom-out-right">
                        <div className="menu-content pb-30">
                        <div className="title text-center">
                            <h3 className="mb-10 title-insights">If you need, Just drop us a line</h3>
                        </div>
                        </div>
                        <div className="my-form">

                            <form className="form-area pb-60 mt-20 position-relative" id="myForm" onSubmit={sendEmail}>
                                
                                <div className="row justify-content-between">

                                    <div className="col-lg-6 form-group">
                                        <input name="from_name" placeholder="Enter your name" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your name'" className="common-input mb-20 form-control" required type="text" />
                                        <input name="from_email" placeholder="Enter email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter email address'" className="common-input mb-20 form-control" required type="email" />
                                        <input name="from_phone" placeholder="Enter your phone" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your phone'" className="common-input mb-20 form-control" required type="phone" />
                                        <button className="send-message-btn fw-medium mb-4">Send Message<span className="lnr lnr-arrow-right" /></button>
                                    </div>
                                    <div className="col-lg-6 form-group">

                                        <textarea className="common-textarea mt-10 form-control" name="message" placeholder="Messege" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Messege'" defaultValue={""} />

                                        <div className="radio-buttons mt-20">
                                            <label className="radio-button">
                                                <input type="radio" name="option" required defaultValue="1Caller" />
                                                <div className="radio-circle" />
                                                <span className="radio-label">1 Cold Caller</span>
                                            </label>
                                            <label className="radio-button">
                                                <input type="radio" name="option" required defaultValue="2to9Callers" />
                                                <div className="radio-circle" />
                                                <span className="radio-label">2 to 9 Callers</span>
                                            </label>
                                            <label className="radio-button">
                                                <input type="radio" name="option" required defaultValue="10+Callers" />
                                                <div className="radio-circle" />
                                                <span className="radio-label">10+ Cold Callers</span>
                                            </label>
                                        </div>
                                    </div>

                                </div>

                            </form>    

                        </div>		
                    </div>
                    <div className="sec-child col-md-5" data-aos="zoom-out-left">
                        <div className="parent d-flex flex-column justify-content-center align-items-center">
                            <div className='mb-20 pb-30'>
                                <h3 className='text-uppercase text-start'><span className='title-insights'>Or pick an</span> Appointment</h3>
                            </div>
                            <div className='mt-10 '>
                                <GoldBtn mylink="https://calendly.com/elitecallers/30min?back=1&month=2024-02" styling="send-message-btn" text="Set An Appointment" targety={true} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

				
        </div>

  )
}

export default HomeForm
