import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import { Outlet } from 'react-router-dom'


function Layout() {


  return (
    <>
          <Navbar />
            <div className='overflow-hidden'>
              <Outlet />
            </div>
          <Footer />

    </>
  )
}

export default Layout