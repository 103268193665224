import React, { useEffect } from 'react'

import img1 from '../../Assets/images/calling phone.jpeg'
import img2 from '../../Assets/images/labtop.jpeg'
import img3 from '../../Assets/images/calender on phone.jpeg'
import img4 from '../../Assets/images/office team.jpeg'
import AOS from 'aos';
import 'aos/dist/aos.css';

function Empowering() {

    useEffect(() => {
        AOS.init(
            {
                delay: 100,
                duration: 2000,
                once: true
            }
        );
    })
  return (


    <div className="mt-5 bg-elite" data-aos="fade-left">
        <div className="container py-5">


                <div>
                    <div className="title mb-10 pl-10">
                        <h1 className="mb-10 ">Our Offered Services</h1>
                        {/* <p className="">Our Offered Services Our extensive service offering is customized to match your unique sales requirements.
                         You will receive unmatched assistance from Elite Callers in creating leads that result in more 
                         closed business. Mastery of Outbound Calling Our virtual assistants are skilled in making 
                         elegant outgoing calls. They possess the charisma, eloquence, and commercial acumen to captivate 
                         prospective clients and make a favorable first impression for your company. Excellence 
                         in Prospect Vetting Finding quality leads is what we do best. The virtual assistants at 
                         Elite Callers carefully review and screen prospects from your data lists to make sure you 
                         only devote your time and energy to the most potential leads.</p> */}


                        
                        <input type="checkbox" className="read-more-state" id="post-1" />
                        <p className="read-more-wrap">Our Offered Services Our extensive service offering is customized to match your unique sales requirements.
                         You will receive unmatched assistance from Elite Callers in creating leads that result in more 
                         closed business. Mastery of Outbound Calling Our virtual assistants are skilled in making 
                         elegant outgoing calls. <span className="read-more-target">They possess the charisma, eloquence, and commercial acumen to captivate 
                         prospective clients and make a favorable first impression for your company. Excellence 
                         in Prospect Vetting Finding quality leads is what we do best. The virtual assistants at 
                         Elite Callers carefully review and screen prospects from your data lists to make sure you 
                         only devote your time and energy to the most potential leads.</span></p>
                        <label htmlFor="post-1" className="read-more-trigger" />




                        



                    </div>
                </div>					
                <div className="row align-items-center justify-content-start">
                    <div className="col-lg-12 m-auto offered-left">
                        <div className="offer-wrap flex-row d-flex row">

                            <div className="col-lg-4 card-empowering col-md-6 col-sm-12">
                                <div className="single-offer mb-30">
                                    <img className="img-fluid rounded-3" src={img4} alt />
                                    <div className="p-card">
                                        <h6>Outbound Calling Mastery</h6>
                                        <p className="p-card">
                                        Experience personalized engagement with our VA-powered outbound calling. Tailored conversations meet your leads' needs, fostering connections and boosting conversion rates. Our VAs are equipped to drive results, ensuring every interaction counts.
                                        </p>
                                    </div>
                                </div> 
                                
                            </div>
                            <div className="col-lg-4 card-empowering col-md-6 col-sm-12">
                                <div className="single-offer mb-30">
                                    <img className="img-fluid rounded-3" src={img2} alt />
                                    <div className="p-card">
                                        <h6>Help for a Smooth Deal Closing</h6>
                                        <p className="p-card">
                                            Our virtual assistants are experts at closing transactions—it's an art. Throughout the sales process, they offer smooth support, guiding you through discussions, overcoming obstacles, and eventually closing agreements successfully.
                                        </p>
                                    </div>
                                </div> 
                            </div>
                            <div className="col-lg-4 card-empowering col-md-6 col-sm-12">
                                <div className="single-offer mb-30">
                                <   img className="img-fluid rounded-3" src={img3} alt />
                                <div className="p-card">
                                        <h6>Proficiency in Setting Appointments</h6>
                                        <p className="p-card">
                                            We handle the headache of scheduling appointments. Our virtual assistants are skilled at setting up appointments with potential customers, freeing you up to concentrate on what you do best—closing sales and expanding your company.
                                        </p>
                                    </div>
                                </div> 
                            </div>
					
                        </div>																	
                    </div>
                    
                </div>


        </div>
    </div>

  )
}

export default Empowering
