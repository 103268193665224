import logo from './logo.svg';
import './App.css';
import Home from './Components/Home/Home';
import Layout from './Components/Layout/Layout';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import { Navigate, RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom';
import Plans from './Components/plans/plans';
import Contact from './Components/Contact/Contact';
import { useEffect, useState } from 'react';
import Loading from './Components/Loading/Loading';




function App() {


  

  let routers = createBrowserRouter([
    {
      path: '', element: <Layout />, children: [
        { index: true, element: <Home /> },

        { path: '/home', element: <Home /> },
        { path: '/plans', element: <Plans /> },
        { path: '/contact', element: <Contact /> },
        { path: '*', element: <Home /> }
      ]
    }
  ])


  return (
    <>
          <RouterProvider router={routers}>

          </RouterProvider>
    </>
  );
}

export default App;
