import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import HomeForm from '../HomeForm/HomeForm'
import ContactForm from '../ContactForm/ContactForm'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet'
import Loading from '../Loading/Loading';

const Contact = () => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    AOS.init(
        {
            delay: 100,
            duration: 2000,
            once: true
        }
    );

    setTimeout(() => setLoading(false), 700)

  })
  return (
    <>
      <Helmet>
          <title>Contact | ELITE CALLERS</title>
      </Helmet>

      {loading === false ? (
          <>
            <div className='py-5 bg-elite mt-100'>
              <div className="py-3 container">



            


                <div className="title" data-aos="zoom-out-down">
                    <h1 className='text-uppercase text-center mb-2'><span className='text-main'>Nice to </span> Hear From You!</h1>
                    <h4 className='text-center mb-5'>To request more information, please fill out the form below.</h4>
                </div>

                <ContactForm />

              </div>
            </div>
          </>
      ) : (
      <Loading />
      )}


    </>
  )
}

export default Contact
