import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
function  Proudly () {

    useEffect(() => {
        AOS.init(
            {
                delay: 200,
                duration: 2000,
                once: true
            }
        );
    })
    return (

    <div className="bg-wavy">
        <div className='py-5 container'>
            <div className="row no-padding justify-content-center d-flex align-items-center g-4">
                <div className="col-lg-6 about-left" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                    <div className="glassy">
                        <h2>
                        why would you choose <br /> ELITE CALLERS ?
                        </h2>
                        
                    </div>

                </div>
                <div className="col-lg-6 about-right no-padding"data-aos="fade-down"  data-aos-anchor-placement="center-bottom">
                    <div className="glassy">
                        <p className='p-glassy'>was founded in the year 2023 in Egypt as an offshore solution for the Real Estate Investor Industry in the USA by an experienced and dedicated team. We exclusively provide marketing & lead generation deliverables for investors seeking premium quality leads with a high lead to contract percentage.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>

  )
}

export default Proudly
