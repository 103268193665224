import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
const Pricing = () => {

    
    useEffect(() => {
        AOS.init(
            {
                delay: 100,
                duration: 2000,
                once: true
            }
        );
    })
  return (
    <div className='py-5 bg-elite'>
      <div className="py-3 container">

        <div className="row justify-content-between g-5">
            <div className="col-lg-4 align-self-center" data-aos="zoom-out-right">
                <div className="getting-start">
                <h2 className='text-uppercase text-start mb-2'><span className='title-insights'>Pricing</span> options</h2>
                    <p className='p-card mb-5'> At Elite Callers, we offer different pricing plans to meet your needs and budget. To get you to your target. Get in contact today. For 5 or more callers, please contact us  directly to discuss exclusive pricing options and packages.</p>
                    <Link to={'/plans'} className='text-black fw-bold font-sm get-startedBtn rounded-4'>Get Started</Link>
                </div>
            </div>

            <div className="col-lg-7" data-aos="zoom-out-left">
                <div className="row g-3">
                    <div className="col-sm-6">
                        <div className="card text-center">
                        <div className="title">
                            <h3>1 CALLER</h3>
                        </div>
                        <div className="price">
                            <h4><sup>$</sup>5 <span className='fs-6'>per hour</span>
                            </h4>
                            <p>Start with 1 caller for your business to call for the number of hours of your liking. </p>
                        </div>
                        <div className="option">
                            <ul>
                            <li> <i className="fa fa-check" aria-hidden="true" /> Cost-Effective Solution </li>
                            <li> <i className="fa fa-check" aria-hidden="true" /> Personalized Attention </li>
                            <li> <i className="fa fa-check" aria-hidden="true" /> Flexibility </li>
                            <li> <i className="fa fa-check" aria-hidden="true" /> Enhanced Efficiency </li>

                            </ul>
                        </div>
                        </div>
                    </div>
                    {/* END Col one */}
                    <div className="col-sm-6">
                        <div className="card text-center">
                        <div className="title">
                            <h3>+2 CALLERS</h3>
                        </div>
                        <div className="price">
                            <h4><sup>$</sup>4 <span className='fs-6'>per hour</span>
                            </h4>
                            <p>Start with 2 or more callers for your business to call for the number of hours to your liking and have a discount.</p>
                        </div>
                        <div className="option">
                            <ul>
                            <li> <i className="fa fa-check" aria-hidden="true" /> Reduced Cost </li>
                            <li> <i className="fa fa-check" aria-hidden="true" /> Increased Outreach </li>
                            <li> <i className="fa fa-check" aria-hidden="true" /> Faster Results </li>
                            <li> <i className="fa fa-check" aria-hidden="true" /> Backup Support </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    {/* END Col two */}
                </div>
            </div>
        </div>










      </div>
    </div>
  )
}

export default Pricing
